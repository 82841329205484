<template>
  <div class="file-upload-container">
    <el-upload
            class="upload-demo"
            :action="actionUrl"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            name="multipartFile"
            :on-success="uploadFileSuccess"
            multiple
            :limit="1"
            :on-exceed="handleExceed"
            :file-list="fileList">
      <el-button type="primary">点击上传</el-button>
      <!--<div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
    </el-upload>
  </div>
</template>

<script>
  export default {
    name: "index.vue",
    data() {
      return {
        fileList: [],
        actionUrl: '/tzapi/upload/putFile'
      };
    },
    methods: {
      uploadFileSuccess(res, file, fileList) {
        this.$emit('uploadFileSuccess', { file: res, fileList: JSON.parse(JSON.stringify(fileList)) })
      },
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview(file) {
        console.log(file);
      },
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${fileList.length} 个文件`);
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`确定移除${file.name}？`);
      }
    }
  }
</script>

<style scoped>

</style>