<template>
  <div class="netTransmit-container">
    <navigator :userInfo="userInfo"></navigator>
    <div style="width: 1200px;margin: auto;padding-bottom: 50px">
      <div class="operate-div">
        <div>网络发信</div>
        <el-button type="primary" @click="toEditNetMessage">发布消息</el-button>
      </div>
      <div class="table-box">
        <div class="table-header table-cell">
          <div class="table-cell-row table-cell-row1">
            标题
          </div>
          <div class="table-cell-row table-cell-row2">
            科室
            <el-select style="width: 160px;margin-left: 10px" v-model="department" @change="departmentFilterChange" clearable placeholder="请选择科室">
              <el-option label="高中部" value="高中部"></el-option>
              <el-option label="课程部" value="课程部"></el-option>
              <el-option label="小学部" value="小学部"></el-option>
              <el-option label="德育部" value="德育部"></el-option>
              <el-option label="体音美部" value="体音美部"></el-option>
              <el-option label="师训部" value="师训部"></el-option>
              <el-option label="干训部" value="干训部"></el-option>
              <el-option label="教育学会" value="教育学会"></el-option>
              <el-option label="初中部" value="初中部"></el-option>
              <el-option label="教科研部" value="教科研部"></el-option>
              <el-option label="宣教部" value="宣教部"></el-option>
              <el-option label="办公室" value="办公室"></el-option>
              <el-option label="学前部" value="学前部"></el-option>
              <el-option label="教务处" value="教务处"></el-option>
              <el-option label="财务室" value="财务室"></el-option>
              <el-option label="技活职成部" value="技活职成部"></el-option>
              <el-option label="总务处" value="总务处"></el-option>
              <el-option label="工会委员会" value="工会委员会"></el-option>
            </el-select>
          </div>
          <div class="table-cell-row table-cell-row3">
            阅读量
          </div>
          <div class="table-cell-row table-cell-row4">
            时间
            <el-date-picker
                    v-model="createTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    clearable
                    @change="selectWebMessage"
                    style="width: 160px;margin-left: 10px"
                    placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="table-cell-row table-cell-row5">
            操作
          </div>
        </div>
        <div v-for="(item, index) in dataList" :key="index" class="table-cell">
          <div class="table-cell-row table-cell-row1">
            {{ item.messageTitle }}
          </div>
          <div class="table-cell-row table-cell-row2">
            <span>{{ item.department }}  - {{ item.departmentPart }}</span>
          </div>
          <div class="table-cell-row table-cell-row3">
            {{ item.readingVolume }}
          </div>
          <div class="table-cell-row table-cell-row4">
            {{ item.createTime }}
          </div>
          <div class="table-cell-row table-cell-row5">
            <el-button @click="toEdit(item)" size="small" type="primary">编辑</el-button>
            <el-button @click="deleteDialog(item)" size="small" type="danger">删除</el-button>
          </div>
        </div>
      </div>
      <div style="width: 100%; text-align: center; padding-top: 15px">
        <el-pagination
                layout="prev, pager, next"
                @current-change="pageChange"
                :page-size="page.pageSize"
                :current-page="page.pageNum"
                :total="page.total">
        </el-pagination>
      </div>
    </div>
    <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="30%">
      <span>确认删除此网络发信？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmDelete">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import navigator from '../userCenter/navigator'
  import cookieUtil from "@/util/cookieUtil"
  export default {
    name: "index",
    components: {
      navigator
    },
    data() {
      return {
        dataList: [],
        pageSize: 20,
        pageNum: 1,
        dialogVisible: false,
        deleteObj: {},
        page: {
          pageSize: 20,
          pageNum: 1,
          total: 0
        },
        department: '',
        createTime: '',
        userInfo: {
          id: '',
          userName: '',
          trueName: '',
          schoolName: ''
        }
      }
    },
    mounted() {
      this.$api.home.getUserInfo().then(res => {
        if (res.code == 200) {
          Object.assign(this.userInfo, res.result)
          cookieUtil.setCookie({
            key: 'userInfo',
            value: JSON.stringify(res.result)
          })
          this.selectWebMessage()
        }
      })
    },
    methods: {
      departmentFilterChange(e) {
        this.department = e
        this.selectWebMessage()
      },
      pageChange(pageNum) {
        this.page.pageNum = pageNum
        this.selectWebMessage()
      },
      selectWebMessage() {
        let { department, createTime } = this
        let { pageSize, pageNum } = this.page
        this.$api.home.selectWebMessage({
          department,
          offset: (parseInt(pageNum) - 1) * pageSize,
          limit: pageSize,
          createTime,
          userId: this.userInfo.id
        }).then(res => {
          this.dataList = res.result.rows
          this.page.total = res.result.total ||[]
        })
      },
      toEditNetMessage() {
        this.$router.push({
          name: 'netMessageDetail'
        })
      },
      deleteDialog(item) {
        this.dialogVisible = true
        this.deleteObj = item
      },
      confirmDelete() {
        this.$api.home.deleteWebMessageById({
          id: this.deleteObj.id,
          userId: this.userInfo.id
        }).then(res => {
          if (res.code == 200) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.dialogVisible = false
            this.selectWebMessage()
          }
        })
      },
      toEdit(item) {
        this.$router.push({
          name: 'netMessageDetail',
          params: {
            id: item.id
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .netTransmit-container {
    background-color: #fff;
    .operate-div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid #ddd;
    }
    .table-box {
      width: 100%;
      padding: 0 20px;
      min-height: 600px;
      background-color: #fff;
    }
    .table-cell {
      display: flex;
    }
    .table-cell-row1 {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .table-cell-row2 {
      display: flex;
      justify-content: center;
      width: 310px;
    }
    .table-cell-row3 {
      width: 100px;
    }
    .table-cell-row4 {
      width: 310px;
      display: flex;
      justify-content: center;
    }
    .table-cell-row5 {
      width: 200px;
    }
    .table-cell-row {
      height: 65px;
      line-height: 65px;
      padding: 0 20px;
      border-bottom: 1px solid #eee;
    }
  }
</style>
